module.exports = {
  
  siteTitle: 'The Carlsbad Movers', // <title>
  manifestName: 'Carlsbad Movers',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: ``, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Get 100% free moving quotes from the top rated movers in San Diego. Get the best movers near you now. Why settle for less when you deserve the best?',
  subHeading: 'Local movers with a reputation for excellence. Need cross country movers? Want a free quote? Want an in home estimate for your move? Have questions about hiring movers in Carlsbad? We are here for you.  ',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-facebook-square',
      name: 'Facebook',
      url: 'https://facebook.com',
    },
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      style: 'brands',
      icon: 'fa-yelp',
      name: 'Yelp',
      url: 'https://yelp.com/',
    },
    {
      style: 'brands',
      icon: 'fa-google',
      name: 'Google',
      url: 'https://google.com/',
    }
  ],
};
