export default {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How much do movers cost?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": `Credible long distance movers cost a minimum of $1500.The exact cost of your move will really depend on exactly
what's moving.This is why its very important to get an in home estimate for any move larger than one or two rooms.`
    }
  }, {
    "@type": "Question",
    "name": "Why use professional movers?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": `There are a few good reasons to hire professional movers. The first reason is, no one really likes being asked to help their friends move. Think about it, do you want to spend 5 hours carrying furniture and loading boxes for a few slices of pizza?
Hiring a few random guys with a truck can prove very expensive because they may not have the experience neccessary to do the best job possible. With professional movers you can know that all of your items will arrive safely. Also hiring unlicensed movers
or even doing it yourself with the help of a friend puts you at a big insurance liability in the case that anyone is injured. With a moving company all of the workers are bonded and insured, plus they already know exactly how to move any items which you need to move safely.
Another good reason to hire professional movers is that a good long distance moving company won't just
help you move your stuff, they will free up valuable time and help you plan your transition into your new home.`
}
}, {
"@type": "Question",
"name": "What is the policy for late delivery of items?",
"acceptedAnswer": {
"@type": "Answer",
"text": `Our local teams work diligently to make sure that your order arrives on time, within our average delivery windows, and our normal delivery hours of 9AM to 8PM in the recipient's time zone. During  busy holiday periods like Christmas, Thanksgiving, and the peak of the summer moving season, we may extend our delivery hours before 9AM to ensure that all shipments are delivered on time. If for any reason your moving truck does not arrive on time, our dedicated Customer Service agents will do everything they can to help successfully resolve your issue.`
}
}, {
"@type": "Question",
"name": "When to book movers?",
"acceptedAnswer": {
"@type": "Answer",
"text": `It's usually best to schedule movers at least 2-3 weeks in advance of your move date. If you are moving in the summer, it's best to schedule your move a few months in advance to save as much money as possible. Keep in mind that we are 100% flexible with your moving dates even after a contract is signed. So you can always reschedule as long as you notify us of any changes up to 3 days before your scheduled moving day.`
}
}, {
"@type": "Question",
"name": "Will I be charged sales tax?",
"acceptedAnswer": {
"@type": "Answer",
"text": `All taxes and fees will be included in the cost on your moving estimate.`}
},
{
"@type": "Question",
"name": "Will movers disconnect a washing machine?",
"acceptedAnswer": {
"@type": "Answer",
"text": `With a full service moving company like The Carlsbad Movers, the movers will disconnect the washer and dryer. The movers will also connect the washer and dryer at your new home.`
}
},
{
"@type": "Question",
"name": "Will the movers pack for you?",
"acceptedAnswer": {
"@type": "Answer",
"text": `Included with the moving service provided by a full service moving company is basic packing. Basic packing means that the movers will wrap all of your furniture items in quilted moving blankets and moving pads. If you would like the movers to pack all of your items for you we can also provide a full packing service, or a white glove service. For many people who are busy working, with kids, etc the best choice is to hire movers to do a full packing, or white glove service.
Packing an entire house can take a very long time. Some people also prefer to know that their things have been professionaly packed so that their items are safe. Feel free to read more about <a href="/fullpacking"> full packing service</a>.`
}

}
]
}
