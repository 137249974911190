import React, { useState , useEffect, useCallback } from 'react';
import { ReCaptcha , loadReCaptcha } from 'react-recaptcha-v3';
import { devMode } from "../../config";

const Contact = () => {
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [from, setFrom] = useState('');
  const [delivery, setDelivery] = useState('');
  const [cell, setCell] = useState('');
  const [home, setHome] = useState('');
  const [date, setDate] = useState('');
  const [rooms, setRooms] = useState('');
  const [formDone, setFormDone] = useState(false);
  const [message, setMessage] = useState('');
  const [thankYou, setThankYou] = useState(false);

  useEffect (() =>{

       loadReCaptcha('6Le3_t0UAAAAAMIcO4ztIdcLf5T4pwDTVve8WO6r');

  }, []);

  const doneForm = useCallback(()=> setFormDone(true), []);

  const submitLeadInfo = async (captchaApiToken) => {
    const formInfo = {
      username,
      email,
      from,
      delivery,
      cell,
      home,
      date,
      rooms,
      // packing:
      // rush:
      message,
      captchaApiToken,
    };

    fetch('https://jshgixo1i5.execute-api.us-east-1.amazonaws.com/prod', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(formInfo),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
     ).then((e)=>{
       console.log("this log should be 200", e);
     }).catch((err)=>{console.error("this shit fucked", err);})

     setThankYou(true);
  };

  const refreshForm = () =>{
    setUserName('');
    setEmail('');
    setFrom('');
    setDelivery('');
    setCell('');
    setHome('');
    setDate('');
    setRooms('');
    setMessage('');
  };

  return (
    <div>
        <a name="moving-quote"></a>
      {thankYou ? (
        <section>
          <div className="modalScreenOverlay"
            onClick= {() => {
                  setThankYou(false);
                  refreshForm();
                }
            }
          >
            <div className="modal">
              <div className="inner"></div>
              <h2>Thank You</h2>
              <p>Your information has been submitted. Your moving agent will contact you as soon as possible. We look forward to working with you.</p>
              <button className ="button primary"
                  onClick={() => {
                    setThankYou(false);
                    refreshForm();

                    }
                  }
              > Go Back
                            </button>

            </div>
          </div>
        </section>
      ) : (
        <section id="cta" className="wrapper style4">
          <div className="inner">
            <div>
              <header>
                <h2>Please tell us what you need</h2>
              </header>
            </div>

            <section>
              <form onSubmit={submitLeadInfo}>
                <div className="row gtr-uniform">
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={event => setUserName(event.target.value)}
                      value={username}
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={event => setEmail(event.target.value)}
                      value={email}
                      placeholder="Email"
                    />
                  </div>

                  <div className="col-6 col-12-xsmall">
                    <input
                      type="number"
                      name="from"
                      id="from"
                      placeholder="From zipcode"
                      onChange={event => setFrom(event.target.value)}
                      value={from}
                    />
                  </div>
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="number"
                      name="delivery"
                      id="delivery"
                      placeholder="To Zipcode"
                      onChange={event => setDelivery(event.target.value)}
                      value={delivery}
                    />
                  </div>

                  <div className="col-6 col-12-xsmall">
                    <input
                      type="tel"
                      name="cell"
                      id="cell"
                      placeholder="Cellphone Number"
                      onChange={event => setCell(event.target.value)}
                      value={cell}
                    />
                  </div>
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="tel"
                      name="home"
                      id="home"
                      placeholder="Home Phone"
                      onChange={event => setHome(event.target.value)}
                      value={home}
                    />
                  </div>

                  <div className="col-12">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      placeholder="when do you want to move"
                      onChange={event => setDate(event.target.value)}
                      value={date}
                    />
                  </div>

                  <div className="col-12">
                    <select
                      name="rooms"
                      id="rooms"
                      onChange={event => setRooms(event.target.value)}
                      value={rooms}
                    >
                      <option value="">- Number of rooms -</option>
                      <option value="1">Studio</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5+">5 or more</option>
                    </select>
                  </div>
                  <div className="col-4 col-12-small">
                    <input
                      type="radio"
                      id="packing-basic"
                      name="packing"
                      defaultChecked
                    />
                    <label htmlFor="packing-basic">Basic Packing</label>
                  </div>
                  <div className="col-4 col-12-small">
                    <input type="radio" id="packing-full" name="packing" />
                    <label htmlFor="packing-full">Full Packing</label>
                  </div>
                  <div className="col-4 col-12-small">
                    <input
                      type="radio"
                      id="packing-white-glove"
                      name="packing"
                    />
                    <label htmlFor="packing-white-glove">White Glove</label>
                  </div>
                  <div className="col-6 col-12-small">
                    <input type="checkbox" id="rush" name="rush" />
                    <label htmlFor="rush">Need movers asap</label>
                  </div>
                  <div className="col-6 col-12-small">
                    <input
                      type="checkbox"
                      id="demo-human"
                      name="demo-human"
                      defaultChecked
                    />
                    <label htmlFor="demo-human">Not a robot</label>
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Enter your message"
                      rows="6"
                      onChange={event => setMessage(event.target.value)}
                      value={message}
                    ></textarea>
                  </div>

                  <div className ="col-12"></div>

                  <div className="col-12">
                    <ul className="actions">
                      <li>
                        <button
                          type="button"
                          onClick={doneForm}
                          value="Send Message"
                          className="primary"
                        >
                        Submit Info
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={refreshForm}>
                          Reset
                          </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </section>
      )}
      {formDone ? (
        <ReCaptcha
          sitekey="6Le3_t0UAAAAAMIcO4ztIdcLf5T4pwDTVve8WO6r"
          action='e_commerce'
          verifyCallback={submitLeadInfo}
         />
      ) : null}
    </div>
  );
};
export default Contact;
