import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li><a href="tel:7602590211"> Call 760-259-0211</a></li>
        <li>2800 Whiptail Loop 92010</li>
        <li>  <a href="https://homeguide.com/long-distance-movers" target="_blank"><img src="//cdn.homeguide.com/assets/images/tmp/homeguide-2020.png" width="150" /></a></li>
      </ul>

    </footer>
  );
}
